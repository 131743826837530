export default function openExternalLinksInNewTab() {
    setTimeout(() => {
        const links = [...document.querySelectorAll('a')];

        if (links.length > 0) {
            links.forEach(it => {
                if (it.host !== window.location.host || it.href.indexOf('/documents/') > -1) {
                    it.setAttribute('target', '_blank');
                    it.setAttribute('rel', 'noopener noreferrer');
                }
            });
        }
    }, 250);
}
