const navigationTriggers = document.querySelectorAll('[data-nav-toggle]');
const navigationItems = document.querySelectorAll('[data-nav-item]');
const navigationClickoutItems = document.querySelectorAll('[data-nav-clickout]');

function toggleMenuOnClickOut(e) {
    if (e.type === 'keydown' && e.keyCode !== 32) {
        return;
    }
    const popupNav = document.getElementById('social-media');
    const popupMenu = document.getElementById('social-media-ul');
    if (popupMenu.classList.contains('open')) {
        popupNav.classList.remove('active');
        popupMenu.classList.remove('open');
    }
}

function toggleMenu(e) {
    if (e.type === 'keydown' && e.keyCode !== 32) {
        return;
    }
    e.preventDefault();
    const popupNav = e.target.nextElementSibling;
    if (popupNav.classList.contains('open')) {
        popupNav.classList.remove('open');
        e.target.classList.remove('active');
    } else {
        popupNav.classList.add('open');
        e.target.classList.add('active');
    }
}

function initialiseNavTriggers() {
    navigationClickoutItems.forEach((el) => {
        el.addEventListener('click', toggleMenuOnClickOut);
        el.addEventListener('keydown', toggleMenuOnClickOut);
    });
    navigationTriggers.forEach((el) => {
        el.addEventListener('click', toggleMenu);
        el.addEventListener('keydown', toggleMenu);
        el.addEventListener('focus', (e) => {
            const popupNav = e.target.nextElementSibling;
            const openTriggers = document.querySelectorAll('ul.open[data-nav-popup]');
            const activeNavItems = document.querySelectorAll('a.active[data-nav-toggle]');
            openTriggers.forEach((openEl) => {
                if (popupNav !== openEl) {
                    openEl.classList.remove('open');
                }
            });
            activeNavItems.forEach((activeEl) => {
                if (el !== activeEl) {
                    activeEl.classList.remove('active');
                }
            });
        });
        el.addEventListener('blur', (e) => {
            setTimeout(() => {
                const { activeElement } = document;
                const parentElement = activeElement.closest(`[data-nav-popup]`);
                if (parentElement) {
                    if (e.target.getAttribute('data-nav-toggle') !== parentElement.getAttribute('data-nav-popup')) {
                        parentElement.classList.remove('open');
                    }
                } else {
                    const openTriggers = document.querySelectorAll('ul.open[data-nav-popup]');
                    openTriggers.forEach((openEl) => {
                        const activeElId = activeElement.getAttribute('data-nav-toggle');
                        const openElId = openEl.getAttribute('data-nav-popup');
                        if (activeElId === null || activeElId !== openElId) {
                            openEl.classList.remove('open');
                        }
                    });
                }
            }, 0);
        });
    });
    navigationItems.forEach((el) => {
        el.addEventListener('blur', (e) => {
            setTimeout(() => {
                const { activeElement } = document;
                if (activeElement.getAttribute('data-nav-item') === null) {
                    const openTriggers = document.querySelectorAll('ul.open[data-nav-popup]');
                    const activeNavItems = document.querySelectorAll('a.active[data-nav-toggle]');
                    openTriggers.forEach((openEl) => {
                        openEl.classList.remove('open');
                    });
                    activeNavItems.forEach((activeEl) => {
                        activeEl.classList.remove('active');
                    });
                }
            }, 0);
        });
    });
}

export default function init() {
    initialiseNavTriggers();
}
